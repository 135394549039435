import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import axios from 'axios';

import { Box, Stack, AppBar, Toolbar, Button } from '@mui/material';
// import axios from '../../../utils/axios';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';

// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
import NavbarHorizontal from '../navbar/NavbarHorizontal';
import { NavSectionHorizontal } from '../../../components/nav-section';

import navConfig from '../navbar/NavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ isCollapse, isOffset, verticalLayout, theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: HEADER.MOBILE_HEIGHT,
  zIndex: theme.zIndex.appBar + 90,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  [theme.breakpoints.up('lg')]: {
    height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    ...(isCollapse && {
      width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    }),
    ...(isOffset && {
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    }),
    ...(verticalLayout && {
      width: '100%',
      height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
      backgroundColor: theme.palette.background.transaprent,
    }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;
  const { enqueueSnackbar } = useSnackbar();

  const isDesktop = useResponsive('up', 'lg');

  const handleBuild = async () => {
    await axios.post('https://67bi63bx3c.execute-api.ap-south-1.amazonaws.com/deployVeducation').then((res) => {
      if (res.data.success) {
        enqueueSnackbar('Build pipeline triggered successfully. To trigger again please wait 40 - 45 minutes.', {
          variant: 'success',
        });
      } else {
        enqueueSnackbar(res.data.msg, { variant: 'error' });
      }
    });
  };

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { lg: 4 },
          mt: 1.3,
          display: 'flex',
          width: '100% !important',

          justifyContent: 'space-between',
        }}
      >
        {isDesktop && verticalLayout && <Logo sx={{ flexShrink: 0 }} />}

        {isDesktop && <NavSectionHorizontal navConfig={navConfig} />}

        {!isDesktop && (
          <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Iconify icon="eva:menu-2-fill" />
          </IconButtonAnimate>
        )}

        {/* <Searchbar /> */}
        {/* <Box sx={{ flexGrow: 1 }} /> */}

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {/* <LanguagePopover /> */}
          {/* <NotificationsPopover /> */}
          {/* <ContactsPopover /> */}
          <Button variant="contained" onClick={handleBuild}>
            Publish
          </Button>
          <AccountPopover />
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
