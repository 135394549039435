import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import userReducer from './slices/user';
import categoryReducer from './slices/categories';
import orderReducer from './slices/order';
import transactionReducer from './slices/wallet';
import bookReducer from './slices/book';
import donationReducer from './slices/productOffers';
import promoReducer from './slices/promo';
import bookCategoryReducer from './slices/book-categories';
import cmsReducer from './slices/cms';
import coursesReducer from './slices/courses';
import courseCategoriesReducer from './slices/course-categories';
import courseLessonReducer from './slices/course-lessons';
import stateListReducer from './slices/state-list';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  mail: mailReducer,
  chat: chatReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  product: productReducer,
  user: userReducer,
  category: categoryReducer,
  order: orderReducer,
  transaction: transactionReducer,
  book: bookReducer,
  donation: donationReducer,
  promo: promoReducer,
  bookCategory: bookCategoryReducer,
  course: coursesReducer,
  cms: cmsReducer,
  courseCategory: courseCategoriesReducer,
  courseLesson: courseLessonReducer,
  stateList: stateListReducer,
});

export { rootPersistConfig, rootReducer };
