import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Chip } from '@mui/material';

//
import Image from '../Image';
import RejectionFiles from './RejectionFiles';
import BlockContent from './BlockContent';

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));

// ----------------------------------------------------------------------

UploadSingleVideoFile.propTypes = {
  error: PropTypes.bool,
  file: PropTypes.oneOfType(PropTypes.string, PropTypes.object),

  helperText: PropTypes.node,
  sx: PropTypes.object,
};

export default function UploadSingleVideoFile({ error = false, file, helperText, sx, ...other }) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  });

  // console.log(file, `videp file`);

  return (
    <Box sx={{ width: '100%', ...sx, height: 'fit-content' }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && {
            padding: '15% 0',
          }),
          objectFit: 'cover',
        }}
      >
        <input {...getInputProps()} />

        {<BlockContent />}
        {/* {file && file.path} */}

        {/* <iframe
          src="https://player.vdocipher.com/v2/?otp=20160313versASE323QfanSVOfLAA2yqGTny75IyZ3aZyL6UaciNpuAyLGlocoeS&playbackInfo=eyJ2aWRlb0lkIjoiZDhhODZmZjRhYzhjNDZlNmE1NGRlMGUxMjM4MzBkY2YifQ=="
          style="border:0;height:360px;width:640px;max-width:100%"
          allowFullScreen="true"
          allow="encrypted-media"
        ></iframe> */}

        {file && file.path && (
          <iframe
            title="Embedded Video"
            // width="300"
            // height="338"
            src={file}
            style={{
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',

              position: 'absolute',
              width: 'calc(80%)',
              height: '80%',
              objectFit: 'contain',
              borderRadius: `24px`,
            }}
            frameBorder="0"
            allowFullScreen
          />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}

      {helperText && helperText}
    </Box>
  );
}
