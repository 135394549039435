// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import Iconify from '../../../components/Iconify';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  // blog: getIcon('ic_blog'),
  cart: getIcon('store'),
  // chat: getIcon('ic_chat'),
  // mail: getIcon('ic_mail'),
  home: getIcon('h'),
  user: getIcon('ic_user'),

  // kanban: getIcon('ic_kanban'),
  // banking: getIcon('ic_banking'),
  // booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  // calendar: getIcon('ic_calendar'),
  // ecommerce: getIcon('ic_ecommerce'),
  // dashboard: getIcon('ic_dashboard'),
  menuItem: getIcon('ic_menu_item'),
  tag: getIcon('ic_tag'),
  analytics: getIcon('ic_analytics'),
  edit: getIcon('ic_edit'),
  offer: getIcon('ic_offer'),
  shop: getIcon('ic_shop'),
  checkout: getIcon('ic_checkout'),
  wallet: getIcon('book'),
  course: getIcon('course'),
  order: getIcon('ic_cart'),
  donation: getIcon('help'),
  coupon: getIcon('coupon'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'Dashboard',
  //   items: [
  //     // { title: 'app', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
  //     { title: 'Analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     // { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
  //     // { title: 'banking', path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
  //     // { title: 'booking', path: PATH_DASHBOARD.general.booking, icon: ICONS.booking },
  //   ],
  // },
  // {
  //   subheader: 'Manage Products',
  //   // path: PATH_DASHBOARD.eCommerce.root,

  //   items: [
  //     { title: 'view product', path: PATH_DASHBOARD.eCommerce.shop, icon: ICONS.shop },
  //     { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView, icon: ICONS.cart },
  //     { title: 'list', path: PATH_DASHBOARD.eCommerce.list, icon: ICONS.list },
  //     { title: 'create', path: PATH_DASHBOARD.eCommerce.new, icon: ICONS.create },

  //     // { title: 'edit', path: PATH_DASHBOARD.eCommerce.demoEdit, icon: ICONS.edit },
  //     // { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout, icon: ICONS.checkout },
  //   ],
  // },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.list,
      //   icon: ICONS.user,
      //   // children: [
      //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //   // { title: 'list', path: PATH_DASHBOARD.user.list },
      //   // { title: 'create', path: PATH_DASHBOARD.user.new },
      //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   // ],
      // },

      {
        title: 'Homepage',
        path: PATH_DASHBOARD.cms.list,
        icon: ICONS.home,
        children: [
          { title: 'Banners', path: PATH_DASHBOARD.cms.list },
          { title: 'Socials', path: PATH_DASHBOARD.cms.social },
          { title: 'Donations', path: PATH_DASHBOARD.productoffers.list },
        ],
      },

      //     // OFFERS
      // {
      //   title: 'Categories ',
      //   path: PATH_DASHBOARD.categories.list,
      //   icon: ICONS.tag,
      //   // children: [
      //   //   { title: 'All Categories', path: PATH_DASHBOARD.categories.list },
      //   //   //   // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //   //   // { title: 'Create new', path: PATH_DASHBOARD.categories.new },
      //   //   //   // { title: ' ', path: PATH_DASHBOARD.eCommerce.list },
      //   // ],
      // },

      //     // E-COMMERCE
      // {
      //   title: 'Yodha Store',
      //   path: PATH_DASHBOARD.eCommerce.list,
      //   icon: ICONS.cart,
      //   // children: [
      //   //   // { title: 'All products', path: PATH_DASHBOARD.eCommerce.shop },
      //   //   // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
      //   //   { title: 'list ', path: PATH_DASHBOARD.eCommerce.list },
      //   //   { title: 'create new', path: PATH_DASHBOARD.eCommerce.new },
      //   // ],
      // },

      //     // OFFERS
      {
        title: 'Library ',
        path: PATH_DASHBOARD.offers.list,
        icon: ICONS.wallet,
        // children: [
        //   { title: 'All Books', path: PATH_DASHBOARD.offers.list },
        //   // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
        //   // { title: 'Books Categories', path: PATH_DASHBOARD.offers.categories },

        //   // { title: ' ', path: PATH_DASHBOARD.eCommerce.list },
        // ],
      },

      {
        title: 'Yodha Store',
        path: PATH_DASHBOARD.eCommerce.list,
        icon: ICONS.cart,
        // children: [
        //   // { title: 'All products', path: PATH_DASHBOARD.eCommerce.shop },
        //   // { title: 'product', path: PATH_DASHBOARD.eCommerce.demoView },
        //   { title: 'list ', path: PATH_DASHBOARD.eCommerce.list },
        //   { title: 'create new', path: PATH_DASHBOARD.eCommerce.new },
        // ],
      },
      // {
      //   title: 'Books Categories',
      //   path: PATH_DASHBOARD.bookCategories.root,
      //   icon: ICONS.wallet,
      //   children: [
      //     { title: 'All Categories', path: PATH_DASHBOARD.bookCategories.list },
      //     { title: 'Search Tags', path: PATH_DASHBOARD.bookCategories.tags },
      //   ],
      // },

      {
        title: 'Courses',
        path: PATH_DASHBOARD.courses.categories,
        icon: ICONS.course,
        // children: [
        // { title: 'All Courses', path: PATH_DASHBOARD.courses.list },
        // { title: 'All Lessons ', path: PATH_DASHBOARD.courses.lessons },
        // { title: 'Categories', path: PATH_DASHBOARD.courses.categories },
        // ],
      },

      // {
      //   title: 'user',
      //   path: PATH_DASHBOARD.user.list,
      //   icon: ICONS.user,
      //   // children: [
      //   // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      //   // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      //   // { title: 'list', path: PATH_DASHBOARD.user.list },
      //   // { title: 'create', path: PATH_DASHBOARD.user.new },
      //   // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      //   // { title: 'account', path: PATH_DASHBOARD.user.account },
      //   // ],
      // },

      {
        title: 'Promo Codes',
        path: PATH_DASHBOARD.promo.list,
        icon: ICONS.offer,
        // children: [
        // { title: 'profile', path: PATH_DASHBOARD.user.profile },
        // { title: 'cards', path: PATH_DASHBOARD.user.cards },
        // { title: 'list', path: PATH_DASHBOARD.order.list },
        // { title: 'create', path: PATH_DASHBOARD.user.new },
        // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
        // { title: 'account', path: PATH_DASHBOARD.user.account },
        // ],
      },

      // Product OFFERS

      // {
      //   title: 'orders',
      //   path: PATH_DASHBOARD.order.list,
      //   icon: ICONS.order,
      // children: [
      // { title: 'profile', path: PATH_DASHBOARD.user.profile },
      // { title: 'cards', path: PATH_DASHBOARD.user.cards },
      // { title: 'list', path: PATH_DASHBOARD.order.list },
      // { title: 'create', path: PATH_DASHBOARD.user.new },
      // { title: 'edit', path: PATH_DASHBOARD.user.demoEdit },
      // { title: 'account', path: PATH_DASHBOARD.user.account },
      // ],
      // },
      {
        title: 'Users',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
      },
      {
        title: 'More',
        path: PATH_DASHBOARD.more.list,
        icon: ICONS.menuItem,
        // children: [
        //   { title: 'Our Gurukuls', path: PATH_DASHBOARD.gurukuls.list },
        //   { title: 'All Lessons ', path: PATH_DASHBOARD.courses.lessons },
        //   { title: 'Categories', path: PATH_DASHBOARD.courses.categories },
        // ],
      },

      // {
      //   title: 'Donations ',
      //   path: PATH_DASHBOARD.productoffers.list,
      //   icon: ICONS.donation,
      //   // children: [
      //   //   { title: 'All Donations', path: PATH_DASHBOARD.productoffers.list },

      //   //   { title: 'Create New', path: PATH_DASHBOARD.productoffers.new },
      //   // ],
      // },

      // INVOICE
      // {
      //   title: 'invoice',
      //   path: PATH_DASHBOARD.invoice.root,
      //   icon: ICONS.invoice,
      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.invoice.list },
      //     { title: 'details', path: PATH_DASHBOARD.invoice.demoView },
      //     { title: 'create', path: PATH_DASHBOARD.invoice.new },
      //     { title: 'edit', path: PATH_DASHBOARD.invoice.demoEdit },
      //   ],
      // },

      //     // BLOG
      //     // {
      //     //   title: 'blog',
      //     //   path: PATH_DASHBOARD.blog.root,
      //     //   icon: ICONS.blog,
      //     //   children: [
      //     //     { title: 'posts', path: PATH_DASHBOARD.blog.posts },
      //     //     { title: 'post', path: PATH_DASHBOARD.blog.demoView },
      //     //     { title: 'create', path: PATH_DASHBOARD.blog.new },
      //     //   ],
      //     // },
      //   ],
      // },

      // // APP
      // // ----------------------------------------------------------------------
      // {
      //   subheader: 'app',
      //   items: [
      //     {
      //       title: 'mail',
      //       path: PATH_DASHBOARD.mail.root,
      //       icon: ICONS.mail,
      //       info: <Label color="error">+32</Label>,
      //     },
      //     { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      //     { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
      //     { title: 'kanban', path: PATH_DASHBOARD.kanban, icon: ICONS.kanban },
    ],
  },

  // // DEMO MENU STATES
  // {
  //   subheader: 'Other cases',
  //   items: [
  //     {
  //       // default roles : All roles can see this entry.
  //       // roles: ['user'] Only users can see this item.
  //       // roles: ['admin'] Only admin can see this item.
  //       // roles: ['admin', 'manager'] Only admin/manager can see this item.
  //       // Reference from 'src/guards/RoleBasedGuard'.
  //       title: 'item_by_roles',
  //       path: PATH_DASHBOARD.permissionDenied,
  //       icon: ICONS.menuItem,
  //       roles: ['admin'],
  //       caption: 'only_admin_can_see_this_item',
  //     },
  //     {
  //       title: 'menu_level_1',
  //       path: '#/dashboard/menu_level_1',
  //       icon: ICONS.menuItem,
  //       children: [
  //         { title: 'menu_level_2a', path: '#/dashboard/menu_level_1/menu_level_2a' },
  //         {
  //           title: 'menu_level_2b',
  //           path: '#/dashboard/menu_level_1/menu_level_2b',
  //           children: [
  //             {
  //               title: 'menu_level_3a',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3a',
  //             },
  //             {
  //               title: 'menu_level_3b',
  //               path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b',
  //               children: [
  //                 {
  //                   title: 'menu_level_4a',
  //                   path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4a',
  //                 },
  //                 {
  //                   title: 'menu_level_4b',
  //                   path: '#/dashboard/menu_level_1/menu_level_2b/menu_level_3b/menu_level_4b',
  //                 },
  //               ],
  //             },
  //           ],
  //         },
  //       ],
  //     },
  //     { title: 'item_disabled', path: '#disabled', icon: ICONS.menuItem, disabled: true },
  //     {
  //       title: 'item_label',
  //       path: '#label',
  //       icon: ICONS.menuItem,
  //       info: (
  //         <Label color="info" startIcon={<Iconify icon="eva:email-fill" />}>
  //           NEW
  //         </Label>
  //       ),
  //     },
  //     {
  //       title: 'item_caption',
  //       path: '#caption',
  //       icon: ICONS.menuItem,
  //       caption:
  //         'Quisque malesuada placerat nisl. In hac habitasse platea dictumst. Cras id dui. Pellentesque commodo eros a enim. Morbi mollis tellus ac sapien.',
  //     },
  //     { title: 'item_external_link', path: 'https://www.google.com/', icon: ICONS.menuItem },
  //   ],
  // },
];

export default navConfig;
