import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'donation';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const donationActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    donation: {
      isLoading: false,
      error: null,
      donations: [],
    },
    donors: {
      isLoading: false,
      error: null,
      users: [],
    },
  };
}

function createExtraActions() {
  return {
    getDonations: getDonations(),
    getDonationsById: getDonationsById(),
    postDonations: postDonations(),
    putDonations: putDonations(),
  };

  function postDonations() {
    return createAsyncThunk(`${name}/postDonations`, async (data) => {
      console.log(data);

      try {
        const response = await axios.post(`/create_donation `, data);

        return response.data;
      } catch (error) {
        console.log(error);

        return error;
      }
    });
  }
  function putDonations() {
    return createAsyncThunk(`${name}/putDonations`, async (data) => {
      try {
        const response = await axios.put(`/edit_donation/${data.id} `, data.formData);

        return response.data;
      } catch (error) {
        console.log(error);

        return error;
      }
    });
  }

  function getDonations() {
    return createAsyncThunk(`${name}/getDonations`, async () => {
      const response = await axios.get(`/get_donation`, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });

      return response.data.donations;
    });
  }
  function getDonationsById() {
    return createAsyncThunk(`${name}/getDonationsById`, async (id) => {
      const response = await axios.get(`/get_donation/${id}`, {
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });
      console.log(response.data);
      return response.data.donations;
    });
  }
}

function createExtraReducers() {
  return {
    ...getDonations(),
    ...postDonations(),
    ...putDonations(),
    ...getDonationsById(),
  };

  function postDonations() {
    const { pending, fulfilled, rejected } = extraActions.postDonations;

    return {
      [pending]: (state) => {
        state.donation.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.donation.isLoading = false;
        // state.donation.error = action.payload.Error;
      },
      [rejected]: (state, action) => {
        console.log(action);
        state.donation.error = action.error;
      },
    };
  }
  function putDonations() {
    const { pending, fulfilled, rejected } = extraActions.putDonations;

    return {
      [pending]: (state) => {
        state.donation.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.donation.isLoading = false;
        // state.donation.error = action.payload.Error;
      },
      [rejected]: (state, action) => {
        console.log(action);
        state.donation.error = action.error;
      },
    };
  }

  function getDonations() {
    const { pending, fulfilled, rejected } = extraActions.getDonations;

    return {
      [pending]: (state) => {
        state.donation.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.donation = {
          donations: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.donation = {
          error: action.error,
          products: [],
          isLoading: false,
        };
      },
    };
  }
  function getDonationsById() {
    const { pending, fulfilled, rejected } = extraActions.getDonationsById;

    return {
      [pending]: (state) => {
        state.donors.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.donors = {
          users: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.donors = {
          error: action.error,
          users: [],
          isLoading: false,
        };
      },
    };
  }
}
