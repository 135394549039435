import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'promo';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const promoActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    promo: {
      isLoading: false,
      error: null,
      codes: [],
    },
  };
}

function createExtraActions() {
  return {
    getPromo: getPromo(),
  };

  function getPromo() {
    return createAsyncThunk(`${name}/getPromo`, async () => {
      const response = await axios.get(`/get_promo`);

      return response.data?.data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getPromo(),
  };

  function getPromo() {
    const { pending, fulfilled, rejected } = extraActions.getPromo;

    return {
      [pending]: (state) => {
        state.promo.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.promo = {
          codes: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.promo.error = action.error;
      },
    };
  }
}
