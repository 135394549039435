import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'courseLessons';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const courseLessonsActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    lesson: {
      isLoading: false,
      error: null,
      lessons: [],
      currentLesson: {},
    },
  };
}

function createExtraActions() {
  return {
    getLessons: getLessons(),
    createLessons: createLessons(),
    editLessons: editLessons(),
    getLessonById: getLessonById(),
    updateLessonById: updateLessonById(),
  };

  function getLessons() {
    return createAsyncThunk(`${name}/getCourseLessons`, async (id) => {
      const response = await axios.get(`/get_course_lessons/${id}`);
      const data = await response.data;
      return data.data;
    });
  }
  function createLessons() {
    return createAsyncThunk(`${name}/createCourseLessons`, async (data) => {
      try {
        const response = await axios.post(`/create_course_category`, data);
        return response;
      } catch (error) {
        return error;
      }
    });
  }

  function editLessons() {
    return createAsyncThunk(`${name}/editCategories`, async ({ id, data }) => {
      try {
        const response = await axios.put(`/edit_course_category/${id}`, data);
        return response;
      } catch (error) {
        return error;
      }
    });
  }

  function getLessonById() {
    return createAsyncThunk(`${name}/getLessonById`, async (id) => {
      const response = await axios.get(`/get_lesson/${id}`);
      const { data } = response.data;
      return data;
    });
  }

  function updateLessonById() {
    return createAsyncThunk(`${name}/updateLessonById`, async (updatedLessonData) => {
      const response = await axios.get(`/edit_lesson/${updatedLessonData._id}`);
      const { data } = response.data;
      return data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getLessons(),
    ...createLessons(),
    ...editLessons(),
    ...getLessonByIdReducer(),
    ...updateLessonByIdReducer(),
  };

  function editLessons() {
    const { pending, fulfilled, rejected } = extraActions.editLessons;

    return {
      [pending]: (state) => {
        state.lessons.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.lessons = {
          isLoading: false,

          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.lessons = {
          error: action.error,
          categories: [],
          isLoading: false,
        };
      },
    };
  }

  function getLessons() {
    const { pending, fulfilled, rejected } = extraActions.getLessons;

    return {
      [pending]: (state) => {
        state.lesson = {
          isLoading: true,
        };
      },

      [fulfilled]: (state, action) => {
        state.lesson = {
          lessons: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.lesson = {
          error: action.error,
          categories: [],
          isLoading: false,
        };
      },
    };
  }
  function createLessons() {
    const { pending, fulfilled, rejected } = extraActions.createLessons;

    return {
      [pending]: (state) => {
        state.lesson = {
          isLoading: true,
        };
      },

      [fulfilled]: (state, action) => {
        state.lesson = {
          isLoading: false,
          lessons: [...state.lesson.lessons],
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.lesson = {
          error: action.error,
          // categories: [],
          isLoading: false,
        };
      },
    };
  }

  function getLessonByIdReducer() {
    const { fulfilled, pending, rejected } = extraActions.getLessonById;
    return {
      [pending]: (state) => {
        state.lesson.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log({ action });
        state.lesson = {
          lessons: state.lesson.lessons,
          isLoading: false,
          error: null,
          currentLesson: action.payload,
        };
      },
      [rejected]: (state, action) => {
        console.log({ action });
        state.lesson = {
          error: action.error,
          lessons: state.lesson.lessons,
          isLoading: false,
          currentLesson: {},
        };
      },
    };
  }

  function updateLessonByIdReducer() {
    const { fulfilled, pending, rejected } = extraActions.updateLessonById;
    return {
      [pending]: (state) => {
        state.lesson.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log({ action });
        state.lesson = {
          lessons: state.lesson.lessons,
          isLoading: false,
          error: null,
          currentLesson: action.payload,
        };
      },
      [rejected]: (state, action) => {
        console.log({ action });
        state.lesson = {
          error: action.error,
          lessons: state.lesson.lessons,
          isLoading: false,
          currentLesson: {},
        };
      },
    };
  }
}
