import { memo } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Container, AppBar, Box } from '@mui/material';
// config
import { HEADER } from '../../../config';
// components
import { NavSectionHorizontal } from '../../../components/nav-section';
//
import navConfig from './NavConfig';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  // transition: theme.transitions.create('top', {
  //   easing: theme.transitions.easing.easeInOut,
  //   duration: theme.transitions.duration.shorter,
  // }),
  // width: '100%',
  // position: 'fixed',
  // left: 0,
  // zIndex: theme.zIndex.appBar + 99,
  // padding: theme.spacing(1, 0),
  // boxShadow: theme.customShadows.z8,
  // top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
  // backgroundColor: theme.palette.background.default,
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  return (
    <RootStyle>
      <Container maxWidth={false}>
        <NavSectionHorizontal navConfig={navConfig} />
      </Container>
    </RootStyle>
  );
}

export default memo(NavbarHorizontal);
