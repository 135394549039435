import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import axios from 'axios';
import axios from '../../utils/axios';
import { HOST_API } from '../../config';

// ----------------------------------------------------------------------

const name = 'books';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const booksActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    books: {
      isLoading: false,
      error: null,
      books: [],
    },

    readers: {
      isLoading: false,
      error: null,
      users: [],
    },
  };
}

function createExtraActions() {
  return {
    getBooks: getBooks(),
    getBookById: getBookById(),
  };

  function getBooks() {
    return createAsyncThunk(`${name}/getBooks`, async () => {
      const response = await axios.get(`/parent`, {
        // headers: { Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });

      const { data } = response.data;

      return data;
    });
  }

  function getBookById() {
    return createAsyncThunk(`${name}/getBookById`, async (id) => {
      const response = await axios.get(`/get_paid_users/${id}`, {
        // headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.getItem('accessToken')}` },
      });

      return response.data.data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getBooks(),
    ...getBookById(),
  };

  function getBookById() {
    const { pending, fulfilled, rejected } = extraActions.getBookById;

    return {
      [pending]: (state) => {
        state.readers.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.readers = {
          users: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.readers.error = action.error;
      },
    };
  }

  function getBooks() {
    const { pending, fulfilled, rejected } = extraActions.getBooks;

    return {
      [pending]: (state) => {
        state.books.isLoading = true;

        // {
        //   isLoading: true,
        // };
      },

      [fulfilled]: (state, action) => {
        state.books = {
          books: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.books.error = action.error;

        // {
        //   error: action.error,
        //   categories: [],
        //   isLoading: false,
        // };
      },
    };
  }
}
