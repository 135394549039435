import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'product';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const courseActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    course: {
      isLoading: false,
      error: null,
      courses: [],
      courseOrderUsers: [],
    },
  };
}

function createExtraActions() {
  return {
    getCourses: getCourses(),
    getAllCourses: getAllCourses(),
    getCourseOrders: getCourseOrders(),
  };

  function getCourses() {
    return createAsyncThunk(`${name}/getCourses`, async (id) => {
      const response = await axios.get(`/courses_by_category/${id}`);

      const { data } = response.data;
      return data;
    });
  }

  function getAllCourses() {
    return createAsyncThunk(`${name}/getAllCourses`, async () => {
      const response = await axios.get('/get_course');
      const { data } = response.data;
      return data;
    });
  }

  function getCourseOrders() {
    return createAsyncThunk(`${name}/getCourseOrders`, async (courseId) => {
      const response = await axios.get(`/get_course_users/${courseId}`);
      const { data } = response.data;
      return data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getCourses(),
    ...getAllCoursesReducer(),
    ...getCourseOrdersReducer(),
  };

  function getCourses() {
    const { pending, fulfilled, rejected } = extraActions.getCourses;

    return {
      [pending]: (state) => {
        state.course.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.course = {
          ...state.course,
          courses: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.course = {
          ...state.course,
          error: action.error,
          courses: [],
          isLoading: false,
        };
      },
    };
  }

  function getAllCoursesReducer() {
    const { fulfilled, pending, rejected } = extraActions.getAllCourses;
    return {
      [pending]: (state) => {
        // ...state.course,
        state.course.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        // console.log({ action });
        state.course = {
          ...state.course,
          courses: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.course = {
          ...state.course,
          error: action.error,
          courses: [],
          isLoading: false,
        };
      },
    };
  }

  function getCourseOrdersReducer() {
    const { fulfilled, pending, rejected } = extraActions.getCourseOrders;
    return {
      [pending]: (state) => {
        // ...state.course,
        state.course.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        // console.log({ action });
        state.course = {
          ...state.course,
          isLoading: false,
          error: null,
          courseOrderUsers: action.payload,
        };
      },
      [rejected]: (state, action) => {
        state.course = {
          ...state.course,
          courseOrderUsers: [],
          error: action.error,
          isLoading: false,
        };
      },
    };
  }
}
