import jwtDecode from 'jwt-decode';
// routes
import { PATH_AUTH } from '../routes/paths';
//
import axios from './axios';

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }

  // const currentDate = new Date();

  // // Specify the number of days to add
  // const numberOfDaysToAdd = 8; // Change this value as needed

  // // Calculate the new date by adding the specified number of days
  // const newDate = new Date(currentDate);
  // newDate.setDate(currentDate.getDate() + numberOfDaysToAdd);

  // // console.log(newDate.getTime());

  const decoded = jwtDecode(accessToken);

  const currentTime = Date.now() / 1000;
  return decoded.iat <= currentTime && decoded.exp > currentTime;
};

const handleTokenExpired = () => {
  let expiredTimer;

  const currentTime = Date.now();

  // Test token expires after 10s
  const timeLeft = currentTime + 100000000 - currentTime; // ~10s
  // const timeLeft = currentTime / 100000;
  // console.log(timeLeft);

  // console.log(timeLeft);

  clearTimeout(expiredTimer);

  expiredTimer = setTimeout(() => {
    // eslint-disable-next-line no-alert
    alert('Token expired');

    localStorage.removeItem('accessToken');

    window.location.href = PATH_AUTH.login;
  }, timeLeft);
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    // This function below will handle when token is expired
    const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
