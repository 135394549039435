import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'book-categories';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const bookcategoryActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    bookCategory: {
      isLoading: false,
      error: null,
      categories: [],
    },

    tags: {
      isLoading: false,
      error: null,
      searchTags: [],
    },

    mainBooks: {
      isLoading: false,
      error: null,
      books: [],
    },
  };
}

function createExtraActions() {
  return {
    getCategories: getCategories(),
    createCategories: createCategories(),
    editCategories: editCategories(),
    getSearchTags: getSearchTags(),
    createTags: createTags(),
    getMainBooks: getMainBooks(),
  };

  function getCategories() {
    return createAsyncThunk(`${name}/getCategories`, async () => {
      const response = await axios.get(`/get_together`);
      return response.data.result;
    });
  }

  function getSearchTags() {
    return createAsyncThunk(`${name}/getTags`, async () => {
      const response = await axios.get(`/get_tags`);
      return response.data;
    });
  }

  function getMainBooks() {
    return createAsyncThunk(`${name}/getMainBooks`, async () => {
      const response = await axios.get(`/get_main_book`);

      const data = await response.data;

      return data.data;
    });
  }

  function createTags() {
    return createAsyncThunk(`${name}/createTags`, async (tags) => {
      try {
        const response = await axios.post(`/createTags`, tags);

        return response.data;
      } catch (error) {
        return error;
      }
    });
  }

  function createCategories() {
    return createAsyncThunk(`${name}/createCategories`, async (data) => {
      console.log(data);

      try {
        const response = await axios.post(`/create_cate_sub`, data);
        return response;
      } catch (error) {
        return error;
      }
    });
  }

  function editCategories() {
    return createAsyncThunk(`${name}/editCategories`, async ({ id, data }) => {
      try {
        const response = await axios.put(`/edit_book_category/${id}`, data);
        return response;
      } catch (error) {
        return error;
      }
    });
  }
}

function createExtraReducers() {
  return {
    ...getCategories(),
    ...getMainBooks(),
    ...getSearchTags(),
    ...createCategories(),

    ...editCategories(),
    ...createTags(),
  };

  function getCategories() {
    const { pending, fulfilled, rejected } = extraActions.getCategories;

    return {
      [pending]: (state) => {
        state.bookCategory = {
          isLoading: true,
        };
      },

      [fulfilled]: (state, action) => {
        state.bookCategory = {
          categories: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.bookCategory = {
          error: action.error,
          categories: [],
          isLoading: false,
        };
      },
    };
  }

  function getMainBooks() {
    const { pending, fulfilled, rejected } = extraActions.getMainBooks;

    return {
      [pending]: (state) => {
        state.mainBooks.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.mainBooks = {
          books: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.mainBooks = {
          error: action.error,
          books: [],
          isLoading: false,
        };
      },
    };
  }

  function getSearchTags() {
    const { pending, fulfilled, rejected } = extraActions.getSearchTags;

    return {
      [pending]: (state) => {
        state.tags.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.tags = {
          searchTags: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.tags = {
          error: action.error,
          searchTags: [],
          isLoading: false,
        };
      },
    };
  }

  function createCategories() {
    const { pending, fulfilled, rejected } = extraActions.createCategories;

    return {
      [pending]: (state) => {
        state.bookCategory = {
          isLoading: true,
        };
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.bookCategory = {
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.bookCategory = {
          error: action.error,
          // categories: [],
          isLoading: false,
        };
      },
    };
  }

  function createTags() {
    const { pending, fulfilled, rejected } = extraActions.createTags;

    return {
      [pending]: (state) => {
        state.tags.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.tags = {
          isLoading: false,
          searchTags: [...state.tags.searchTags, ...action?.payload?.data],
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.tags = {
          error: action.error,
          searchTags: [...state.tags.searchTags],
          isLoading: false,
        };
      },
    };
  }

  function editCategories() {
    const { pending, fulfilled, rejected } = extraActions.editCategories;

    return {
      [pending]: (state) => {
        state.bookCategory.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.bookCategory.isLoading = false;
      },
      [rejected]: (state, action) => {
        state.bookCategory.isLoading = false;
        state.bookCategory.error = action.error;
      },
    };
  }
}
