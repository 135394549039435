import React, { createContext, useContext, useState } from 'react';

// Step 1: Create a context to store the array of IDs
const MoveItemsContext = createContext();

// Step 2: Create a context provider component
const MoveItemsProvider = ({ children }) => {
  // Step 3: Define the state to hold the array of IDs
  const [selectedIds, setSelectedIds] = useState([]);

  // Function to add an ID to the array
  const addId = (id) => {
    setSelectedIds((prevIds) => [...prevIds, id]);
  };

  // Function to remove an ID from the array
  const removeId = (id) => {
    setSelectedIds((prevIds) => prevIds.filter((itemId) => itemId !== id));
  };

  // Function to clear all selected IDs
  const clearIds = () => {
    setSelectedIds([]);
  };

  return (
    <MoveItemsContext.Provider value={{ selectedIds, addId, removeId, clearIds, setSelectedIds }}>
      {children}
    </MoveItemsContext.Provider>
  );
};

// Step 4: Use the context provider in your application
export { MoveItemsProvider, MoveItemsContext };

// Now you can use the context in child components like this:
// const { selectedIds, addId, removeId, clearIds } = useContext(MoveItemsContext);
