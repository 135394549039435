import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'new-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element: <GeneralApp /> },
        // { path: 'ecommerce', element: <GeneralEcommerce /> },
        { path: 'analytics', element: <GeneralEcommerce /> },
        // { path: 'banking', element: <GeneralBanking /> },
        // { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'e-commerce',
          children: [
            { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
            { path: 'shop', element: <EcommerceShop /> },
            { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list/:name', element: <ProductList /> },

            { path: 'list', element: <EcommerceProductList /> },
            { path: 'product/new', element: <EcommerceProductCreate /> },
            { path: 'product/:name/edit', element: <EcommerceProductEdit /> },
            { path: 'product/reviews/:productId/list', element: <ProductReviewList /> },
            { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'gurukuls',
          children: [
            { element: <Navigate to="/dashboard/gurukuls/list" replace />, index: true },

            { path: 'list/:name', element: <CityList /> },
            { path: 'list/:name/:city', element: <GurukulsList /> },

            { path: 'list', element: <StateList /> },
          ],
        },
        {
          path: 'more',
          children: [
            { element: <Navigate to="/dashboard/more/list" replace />, index: true },

            { path: 'list/:name', element: <MoreContent /> },

            { path: 'list', element: <MoreList /> },
          ],
        },

        {
          path: 'offers',
          children: [
            { element: <Navigate to="/dashboard/offers" replace />, index: true },
            // { path: '', element: <EcommerceShop /> },
            // { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <NewLibrary /> },
            { path: 'new', element: <OfferCreate /> },
            { path: ':chapter/edit', element: <OfferEdit /> },
            { path: ':id/readers', element: <ReadersList /> },
            { path: 'categories', element: <BookCategories /> },
            { path: 'categories/new ', element: <BookCategoriesCreate /> },

            //   { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },

        {
          path: 'book-categories',
          children: [
            { element: <Navigate to="/dashboard/book-categories" replace />, index: true },
            { path: 'list', element: <BookCategories /> },
            { path: 'new', element: <BookCategoriesCreate /> },
            { path: ':category/edit', element: <BookCategoriesEdit /> },
            { path: 'tags', element: <SearchTags /> },

            //   { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },

        {
          path: 'product-offers',
          children: [
            { element: <Navigate to="/dashboard/product-offers" replace />, index: true },
            // { path: '', element: <EcommerceShop /> },
            // { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <ProductOfferList /> },
            { path: 'new', element: <ProductOfferCreate /> },
            { path: ':name/edit', element: <ProductOfferEdit /> },
            { path: ':id/donations', element: <DonorList /> },
            //   { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'categories',
          children: [
            { element: <Navigate to="/dashboard/categories" replace />, index: true },
            // { path: '', element: <EcommerceShop /> },
            // { path: 'product/:name', element: <EcommerceProductDetails /> },
            { path: 'list', element: <CategoryList /> },
            { path: 'new', element: <CategoryCreate /> },
            { path: ':category/edit', element: <CategoryEdit /> },
            //   { path: 'checkout', element: <EcommerceCheckout /> },
          ],
        },
        {
          path: 'user',
          children: [
            //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            //     { path: 'profile', element: <UserProfile /> },
            //     { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            //     { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <UserCreate /> },
            //     { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'cms',
          children: [
            //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            //     { path: 'profile', element: <UserProfile /> },
            //     { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <CMSList /> },
            { path: 'social', element: <CMSSocial /> },
            { path: 'new', element: <CMSCreate /> },
            { path: ':name/edit', element: <CMSEdit /> },
            // { path: ':name/edit', element: <UserCreate /> },
            //     { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'courses',
          children: [
            { path: 'list', element: <CoursesList /> },
            { path: 'new', element: <CoursesCreate /> },
            { path: ':name/edit', element: <CoursesEdit /> },
            { path: 'categories', element: <CourseCategories /> },
            { path: 'categories/new', element: <CourseCategoriesCreate /> },
            { path: 'categories/:id/list', element: <CoursesList /> },
            { path: 'reviews/:id/list', element: <CourseReviewList /> },
            { path: 'reviews/:courseId/create', element: <CourseReviewCreate /> },
            { path: 'lessons/:id/list', element: <CourseLessons /> },

            // { path: 'categories/lessons/list/:id', element: <CourseLessons /> },
            { path: 'lessons/new', element: <CourseLessonsCreate /> },
            { path: 'lessons/:lesson/edit', element: <CourseLessonsEdit /> },
            { path: 'orders/list', element: <CoursesOrderList /> },
            { path: 'orders/:courseId/list', element: <CourseOrderUsersList /> },
          ],
        },
        {
          path: 'order',
          children: [
            //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            //     { path: 'profile', element: <UserProfile /> },
            //     { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <OrderList /> },
            //     { path: 'new', element: <UserCreate /> },
            // { path: ':name/edit', element: <OrderCreate /> },
            //     { path: 'account', element: <UserAccount /> },
          ],
        },

        {
          path: 'promo',
          children: [
            //     { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            //     { path: 'profile', element: <UserProfile /> },
            //     { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <PromoList /> },
            { path: 'new/:type', element: <PromoCreate /> },
            { path: ':id/edit', element: <PromoEdit /> },
            //     { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'invoice',
          children: [
            { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
            { path: 'list', element: <InvoiceList /> },
            { path: ':id', element: <InvoiceDetails /> },
            { path: ':id/edit', element: <InvoiceEdit /> },
            { path: 'new', element: <InvoiceCreate /> },
          ],
        },
        // {
        //   path: 'blog',
        //   children: [
        //     { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
        //     { path: 'posts', element: <BlogPosts /> },
        //     { path: 'post/:title', element: <BlogPost /> },
        //     { path: 'new', element: <BlogNewPost /> },
        //   ],
        // },
        // {
        //   path: 'mail',
        //   children: [
        //     { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
        //     { path: 'label/:customLabel', element: <Mail /> },
        //     { path: 'label/:customLabel/:mailId', element: <Mail /> },
        //     { path: ':systemLabel', element: <Mail /> },
        //     { path: ':systemLabel/:mailId', element: <Mail /> },
        //   ],
        // },
        // {
        //   path: 'chat',
        //   children: [
        //     { element: <Chat />, index: true },
        //     { path: 'new', element: <Chat /> },
        //     { path: ':conversationKey', element: <Chat /> },
        //   ],
        // },
        // { path: 'calendar', element: <Calendar /> },
        // { path: 'kanban', element: <Kanban /> },
        // { path: 'permission-denied', element: <PermissionDenied /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        // { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      // children: [
      //   { element: <HomePage />, index: true },
      //   { path: 'about-us', element: <About /> },
      //   { path: 'contact-us', element: <Contact /> },
      //   { path: 'faqs', element: <Faqs /> },
      // ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));

// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const GeneralEcommerce = Loadable(lazy(() => import('../pages/dashboard/GeneralEcommerce')));
// const GeneralAnalytics = Loadable(lazy(() => import('../pages/dashboard/GeneralAnalytics')));
// const GeneralBanking = Loadable(lazy(() => import('../pages/dashboard/GeneralBanking')));
// const GeneralBooking = Loadable(lazy(() => import('../pages/dashboard/GeneralBooking')));

// ECOMMERCE
const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));
const ProductReviewList = Loadable(lazy(() => import('../pages/dashboard/ProductReviewList')));

// More
const StateList = Loadable(lazy(() => import('../pages/dashboard/StateList')));
const CityList = Loadable(lazy(() => import('../pages/dashboard/CityList')));

const GurukulsList = Loadable(lazy(() => import('../pages/dashboard/GurukulsList')));
const MoreContent = Loadable(lazy(() => import('../pages/dashboard/MoreContent')));

const MoreList = Loadable(lazy(() => import('../pages/dashboard/MoreList')));

// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
// const EcommerceProductList = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductList')));
// const EcommerceProductCreate = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductCreate')));
// const EcommerceProductEdit = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductEdit')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));
// const ProductList = Loadable(lazy(() => import('../pages/dashboard/ProductList')));

// OFFERS
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const OfferList = Loadable(lazy(() => import('../pages/dashboard/OfferList')));
const NewLibrary = Loadable(lazy(() => import('../pages/dashboard/NewLibrary')));

const OfferCreate = Loadable(lazy(() => import('../pages/dashboard/OfferCreate')));
const OfferEdit = Loadable(lazy(() => import('../pages/dashboard/OfferEdit')));
const ReadersList = Loadable(lazy(() => import('../pages/dashboard/ReadersList')));

//
const BookCategories = Loadable(lazy(() => import('../pages/dashboard/BookCategories')));
const BookCategoriesCreate = Loadable(lazy(() => import('../pages/dashboard/BookCategoriesCreate')));
const BookCategoriesEdit = Loadable(lazy(() => import('../pages/dashboard/BookCategoriesEdit')));
const SearchTags = Loadable(lazy(() => import('../pages/dashboard/SearchTags')));

// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// PRODUCT OFFERS
// const EcommerceShop = Loadable(lazy(() => import('../pages/dashboard/EcommerceShop')));
// const EcommerceProductDetails = Loadable(lazy(() => import('../pages/dashboard/EcommerceProductDetails')));
const ProductOfferList = Loadable(lazy(() => import('../pages/dashboard/ProductOfferList')));
const ProductOfferCreate = Loadable(lazy(() => import('../pages/dashboard/ProductOfferCreate')));
const ProductOfferEdit = Loadable(lazy(() => import('../pages/dashboard/ProcductOfferEdit')));
const DonorList = Loadable(lazy(() => import('../pages/dashboard/DonorList')));
// const EcommerceCheckout = Loadable(lazy(() => import('../pages/dashboard/EcommerceCheckout')));

// CATEGORY
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const CategoryCreate = Loadable(lazy(() => import('../pages/dashboard/CategoriesCreate')));
const CategoryEdit = Loadable(lazy(() => import('../pages/dashboard/CategoriesEdit')));

// PROMO

const PromoList = Loadable(lazy(() => import('../pages/dashboard/PromoList')));
const PromoCreate = Loadable(lazy(() => import('../pages/dashboard/PromoCreate')));
const PromoEdit = Loadable(lazy(() => import('../pages/dashboard/PromoEdit')));

// INVOICE
const InvoiceList = Loadable(lazy(() => import('../pages/dashboard/InvoiceList')));
const InvoiceDetails = Loadable(lazy(() => import('../pages/dashboard/InvoiceDetails')));
const InvoiceCreate = Loadable(lazy(() => import('../pages/dashboard/InvoiceCreate')));
const InvoiceEdit = Loadable(lazy(() => import('../pages/dashboard/InvoiceEdit')));

// BLOG
// const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
// const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
// const BlogNewPost = Loadable(lazy(() => import('../pages/dashboard/BlogNewPost')));

// USER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// ORDER
// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const OrderList = Loadable(lazy(() => import('../pages/dashboard/OrderList')));
const CMSList = Loadable(lazy(() => import('../pages/dashboard/CMSList')));
const CMSSocial = Loadable(lazy(() => import('../pages/dashboard/CMSSocial')));

const CMSCreate = Loadable(lazy(() => import('../pages/dashboard/CMSCreate')));
const CMSEdit = Loadable(lazy(() => import('../pages/dashboard/CMSEdit')));

const CourseCategories = Loadable(lazy(() => import('../pages/dashboard/CourseCategories')));
const CourseCategoriesCreate = Loadable(lazy(() => import('../pages/dashboard/CourseCategoriesCreate')));
const CourseCategoriesEdit = Loadable(lazy(() => import('../pages/dashboard/CourseCategoriesEdit')));
const CoursesList = Loadable(lazy(() => import('../pages/dashboard/CoursesList')));
const CourseReviewList = Loadable(lazy(() => import('../pages/dashboard/CourseReviewList')));
const CourseReviewCreate = Loadable(lazy(() => import('../pages/dashboard/CourseReviewCreate')));
const CoursesCreate = Loadable(lazy(() => import('../pages/dashboard/CoursesCreate')));
const CoursesEdit = Loadable(lazy(() => import('../pages/dashboard/CoursesEdit')));
const CourseLessons = Loadable(lazy(() => import('../pages/dashboard/CourseLessons')));
const CourseLessonsCreate = Loadable(lazy(() => import('../pages/dashboard/CourseLessonsCreate')));
const CourseLessonsEdit = Loadable(lazy(() => import('../pages/dashboard/CourseLessonsEdit')));
const CoursesOrderList = Loadable(lazy(() => import('../pages/dashboard/CoursesOrderList')));
const CourseOrderUsersList = Loadable(lazy(() => import('../pages/dashboard/CourseOrderUsersList')));

// const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
// const OrderCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));

// APP
// const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
// const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
// const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
// const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));

const Page404 = Loadable(lazy(() => import('../pages/Page404')));
