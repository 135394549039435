import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'cms';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const cmsActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    cms: {
      isLoading: false,
      error: null,
      data: {},
    },
  };
}

function createExtraActions() {
  return {
    getCMS: getCMS(),
  };

  function getCMS() {
    return createAsyncThunk(`${name}/getCMS`, async () => {
      const response = await axios.get(`/get_social_links`);
      console.log(response.data);
      return response.data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getCMS(),
  };

  function getCMS() {
    const { pending, fulfilled, rejected } = extraActions.getCMS;

    return {
      [pending]: (state) => {
        state.cms.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.cms = {
          data: { ...action.payload },
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.cms.error = action.error;
      },
    };
  }
}
