import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'product';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const productActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    product: {
      isLoading: false,
      error: null,
      products: [],
    },
  };
}

function createExtraActions() {
  return {
    getProducts: getProducts(),
  };

  function getProducts() {
    return createAsyncThunk(`${name}/getProducts`, async () => {
      const response = await axios.get(`/findAll`);

      const { data } = response.data;
      return data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getProducts(),
  };

  function getProducts() {
    const { pending, fulfilled, rejected } = extraActions.getProducts;

    return {
      [pending]: (state) => {
        state.product = {
          isLoading: true,
        };
      },

      [fulfilled]: (state, action) => {
        state.product = {
          products: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.product = {
          error: action.error,
          products: [],
          isLoading: false,
        };
      },
    };
  }
}
