import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'state-list';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const stateListActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    stateList: {
      isLoading: false,
      error: null,
      states: [],
    },
  };
}

function createExtraActions() {
  return {
    getStates: getStates(),
  };

  function getStates() {
    return createAsyncThunk(`${name}/getStates`, async () => {
      const response = await axios.get(`/getStates`);
      return response.data;
    });
  }
}

function createExtraReducers() {
  return {
    ...getStates(),
  };

  function getStates() {
    const { pending, fulfilled, rejected } = extraActions.getStates;

    return {
      [pending]: (state) => {
        state.stateList = {
          isLoading: true,
        };
      },

      [fulfilled]: (state, action) => {
        console.log(action.payload);

        state.stateList = {
          states: action.payload.data,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.stateList = {
          error: action.error,
          states: [],
          isLoading: false,
        };
      },
    };
  }
}
