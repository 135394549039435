import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const name = 'order';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({
  name,
  initialState,
  extraReducers,
});

export const orderActions = { ...slice.actions, ...extraActions };
export default slice.reducer;

function createInitialState() {
  return {
    order: {
      isLoading: false,
      error: null,
      orders: [],
    },
  };
}

function createExtraActions() {
  return {
    getOrders: getOrders(),
    updateOrdersStatus: updateOrdersStatus(),
  };

  function getOrders() {
    return createAsyncThunk(`${name}/getOrders`, async () => {
      const response = await axios.get(`/show_order`);
      return response.data;
    });
  }
  function updateOrdersStatus() {
    return createAsyncThunk(`${name}/updateOrdersStatus`, async (data) => {
      try {
        const response = await axios.put(`/change_orderStatus`, data);
        return response;
      } catch (error) {
        return error;
      }
    });
  }
}

function createExtraReducers() {
  return {
    ...getOrders(),
    ...updateOrdersStatus(),
  };

  function getOrders() {
    const { pending, fulfilled, rejected } = extraActions.getOrders;

    return {
      [pending]: (state) => {
        state.order.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        state.order = {
          orders: action.payload,
          isLoading: false,
          error: null,
        };
      },
      [rejected]: (state, action) => {
        state.order.error = action.error;

        // {
        //   error: action.error,
        //   categories: [],
        //   isLoading: false,
        // };
      },
    };
  }
  function updateOrdersStatus() {
    const { pending, fulfilled, rejected } = extraActions.updateOrdersStatus;

    return {
      [pending]: (state) => {
        state.order.isLoading = true;
      },

      [fulfilled]: (state, action) => {
        console.log(
          state.order.orders.map((order, index) =>
            order.orderId === action.payload.data.data[index]?.orderId ? action.payload.data.data[index] : order
          )
        );

        state.order = {
          orders: state.order.orders.map((order, index) =>
            order.orderId === action.payload.data.data[index]?.orderId ? action.payload.data.data[index] : order
          ),
          isLoading: false,
          error: null,
        };

        // return (
        // order.orderId === action.payload.data.data[index].orderId ? action.payload.data.data[index] : order;

        // )

        // state.order.orders = state.order.orders.map((order, index) =>
        //   order._id === action.payload.data.data[index]._id ? action.payload.data.data[index] : order
        // );
      },
      [rejected]: (state, action) => {
        state.order.error = action.error;

        // {
        //   error: action.error,
        //   categories: [],
        //   isLoading: false,
        // };
      },
    };
  }
}
