// routes
import { useEffect } from 'react';
import Router from './routes';
import { productActions } from './redux/slices/product';
// theme
import ThemeProvider from './theme';
// components
import ThemeSettings from './components/settings';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import { dispatch } from './redux/store';
import { booksActions } from './redux/slices/book';
import { HOST_API } from './config';

// ----------------------------------------------------------------------

export default function App() {
  const { getProducts } = productActions;
  const { getBooks } = booksActions;

  useEffect(() => {
    dispatch(getBooks());
    dispatch(getProducts());
  }, []);

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        {/* <ThemeSettings> */}
        <NotistackProvider>
          <ProgressBarStyle />
          <ChartStyle />
          <ScrollToTop />
          <Router />
        </NotistackProvider>
        {/* </ThemeSettings> */}
      </ThemeProvider>
    </MotionLazyContainer>
  );
}
